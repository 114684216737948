<template>
  <div id="about">
    <page-content
      class="about-page-header"
      title="About Us"
      subtitle="Nostratech is a technology consulting company driven by a group of
        young, creative, enthusiastic technical professionals ready to help
        build the next great digital product and system. Our expertise include
        product development, support &amp; maintenance system and engineering."
    >
      <img v-scrollanimation="'up'" class="page-img reguler-shadow reguler-border-radius" src="@/assets/images/about/img-about-page.jpeg" alt="about us image" />
    </page-content>
    <story-section />
    <partner-section />
    <values-section />
    <join-section />
  </div>
</template>

<script>
const PageContent = () => import("@/components/PageContent.vue");
const StorySection = () => import("./Section/StorySection.vue");
const PartnerSection = () => import("./Section/PartnerSection.vue");
const ValuesSection = () => import("./Section/ValuesSection.vue");
const JoinSection = () => import("./Section/JoinSection.vue");

export default {
  name: "AboutUs",
  components: {
    PageContent,
    StorySection,
    PartnerSection,
    ValuesSection,
    JoinSection
  },
};
</script>
